import "./../_lib/leaflet/leaflet-src";

$(function() {
    const mapContainer = $(".map-container");
    mapContainer.each(function() {
        const mapId = $(this).attr("id");

        const mapEl = $(`#${mapId}`);

        // Latitude|Longitude|Zoom
        const coordinates = mapEl.data("coordinates").split("|");
        var mymap = L.map(mapId).setView(
            [coordinates[0], coordinates[1]],
            coordinates[2]
        );
        var marker = L.marker([coordinates[0], coordinates[1]]).addTo(mymap);

        mapEl.data("loaded", false);
        mapEl.data("map", mymap);

        L.tileLayer(
            "https://umdk.{s}.tile.maphost.dk/da/map/v1/{z}/{x}/{y}.png",
            {
                attribution:
                    'Kortdata &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap-bidragsydere</a>, Kort &copy; <a href="https://www.map-services.dk/" target="_blank">Map Services</a>',
                maxZoom: 19
            }
        ).addTo(mymap);

        //Reload map after accordion has been toggled first time
        mapEl.parentsUntil(".map-accordion").on("accordionToggled", e => {
            var mapContainer = $(".map-container", e.target);
            var mapLoaded = mapContainer.data("loaded");
            if (!mapLoaded) {
                mapContainer.data("loaded", true);
                var mapContainerMap = mapContainer.data("map");
                mapContainerMap.invalidateSize();
            }
        });
    });
});
